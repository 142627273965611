/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "react-router-dom";

function Article({ title, des, publish, author }) {
  return (
    <div className=" col-12">
      <div className="mb-3 border-bottom  py-4">
        <a href="#" className="fw-bold">
          <h2 className="h5">{title}</h2>
        </a>
        <p className="fs-sm mb-2 text-brand">
          {publish} - {author}
        </p>
        <p className="fs-sm mb-2 secondary">{des}</p>
      </div>
    </div>
  );
}

export default function Research() {
  return (
    <main className="main">
      <div className="container  app-width">
        <div className="gap-3" />
        <div className="row">
          <div className="col-12">
            <h1>Research</h1>
            {/* <p className="text-secondary fw-bold">
              <span className="terminal">We are</span>
            </p> */}
          </div>
        </div>

        <div className="row">
          <Article
            title="Generating secure randomness on Ethereum using SNARKs"
            des="In this post, we propose designs and reference implementations that utilize SNARKs and VDFs to achieve fully secure randomness on Ethereum."
            publish="Jan 12, 2023"
            author="Georgios Konstantopoulos"
          />
          <Article
            title="Generating secure randomness on Ethereum using SNARKs"
            des="In this post, we propose designs and reference implementations that utilize SNARKs and VDFs to achieve fully secure randomness on Ethereum."
            publish="Jan 12, 2023"
            author="Georgios Konstantopoulos"
          />
          <Article
            title="Generating secure randomness on Ethereum using SNARKs"
            des="In this post, we propose designs and reference implementations that utilize SNARKs and VDFs to achieve fully secure randomness on Ethereum."
            publish="Jan 12, 2023"
            author="Georgios Konstantopoulos"
          />
          <Article
            title="Generating secure randomness on Ethereum using SNARKs"
            des="In this post, we propose designs and reference implementations that utilize SNARKs and VDFs to achieve fully secure randomness on Ethereum."
            publish="Jan 12, 2023"
            author="Georgios Konstantopoulos"
          />
        </div>
        <div className="gap-6" />
      </div>
    </main>
  );
}
