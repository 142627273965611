/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../assets/images/dri_venture_logo.svg";
import { Link, NavLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

function MenuList(params) {
  return (
    <>
      <li>
        <NavLink
          exact={true}
          to="/"
          activeClassName="active"
          className="nav-link"
        >
          About
        </NavLink>
      </li>
      <li>
        <NavLink
          exact={true}
          to="/portfolio"
          activeClassName="active"
          className="nav-link"
        >
          Portfolio
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          exact={true}
          to="/research"
          activeClassName="active"
          className="nav-link"
        >
          Research
        </NavLink>
      </li> */}
      {/* <li>
        <NavLink
          exact={true}
          to="/career"
          activeClassName="active"
          className="nav-link"
        >
          Career
        </NavLink>
      </li> */}
    </>
  );
}

export default function Header() {
  return (
    <div className="header sticky-top">
      <div className="container-lg py-2 app-width">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link to="/" className="">
              <img src={logo} alt="" width="60" />
            </Link>
          </div>
          <div className="d-none d-md-flex">
            <ul
              className="nav d-none d-md-flex ms-md-auto"
              style={{ alignItems: "center" }}
            >
              <MenuList />
            </ul>
          </div>
        </div>
      </div>

      <div className="d-md-none">
        <input type="checkbox" className="toggler"></input>
        <div className="hamburger">
          <div></div>
        </div>

        <div className="menu shadow">
          <div>
            <ul>
              <MenuList />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
