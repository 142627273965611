/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "react-router-dom";

function Investment(params) {
  return (
    <div className=" col-12 col-md-6 mb-3">
      <div className="card h-100 bg-none text-start card-hover">
        <div className="logo-cover bg-white rounded-3 w-auto lh-1 mt-4 ms-2 me-auto">
          <img
            className="logo-img"
            src={require("../assets/images/logo/" + params.logo).default}
            alt=""
          />
        </div>
        <div className="card-body">
          <h3 className="fw-bold h5">{params.name}</h3>
          <p className="fs-sm mb-2 secondary">{params.des}</p>
          <p className="fs-sm mb-2 text-brand">{params.round}</p>
        </div>
      </div>
    </div>
  );
}

export default function Portfolio() {
  return (
    <main className="main">
      <div className="container app-width">
        <div className="gap-3" />
        <div className="row">
          <div className="col-12">
            <h1>Portfolio</h1>
          </div>
        </div>

        <div className="row">
          <Investment
            name="BirdBirdGo"
            des="A Flappy Bird style Web3 game."
            logo="bbg.png"
            round="Incubate"
          />
          <Investment
            name="Pepacman"
            des="A Pacman style Web3 game."
            logo="eat-1.gif"
            round="Incubate"
          />
          <Investment
            name="Gafi Network"
            des="A blockchain designed for gamers."
            logo="gafi.png"
            round=""
          />
          <Investment
            name="Heroes and Empires"
            des="The next-gen NFTs RPG gaming project."
            logo="he.webp"
            round="Seed round"
          />

          {/* <Investment
            name="Polkadot"
            des="Parachains, a truly interoperable decentralized web."
            logo="dot.png"
            round="Public"
          /> */}
          {/* <Investment
            name="Fantom"
            des="Fast, high-throughput open-source smart contract platform."
            logo="ftm.png"
            round="Public"
          /> */}

          {/* <Investment
            name="Uniswap Protocol"
            des="Swap, earn, and build on the leading decentralized crypto trading protocol."
            logo="uni.png"
            round="Public"
          /> */}
          <Investment
            name="Ethereum"
            des="Decentralized applications, DeFi's home."
            logo="eth.png"
            round="Public"
          />
          <Investment
            name="Starknet"
            des="Secure scaling technology bringing Ethereum’s benefits to the world."
            logo="strk.png"
            round="Public"
          />

          {/* <Investment
            name="Casper Network"
            des="The first proof-of-stake blockchain built off the Casper CBC specification."
            logo="casper.png"
            round="Public"
          /> */}
          <Investment
            name="Ancient8"
            des="Democratizing social and financial access in the Metaverse."
            logo="ancient8.png"
            round="Public"
          />
          <Investment
            name="Star Atlas"
            des="A grand strategy game of space exploration, territorial conquest, political domination, and more."
            logo="atlas.png"
            round="Public"
          />

          {/* <Investment
            name="SubQuery"
            des="Query decentralised data faster."
            logo="subquery.png"
            round="Seed round"
          /> */}
          {/* <Investment
            name="Supra Oracles"
            des="Oracles project providing blockchains better, faster, accurate, and more secure off-chain data."
            logo="SupraOracles.png"
            round="Public"
          /> */}

          <Investment
            name="Emboldn"
            des="A comic book series, web game & an immersive fighting game."
            logo="emboldn.png"
            round="Public"
          />
          {/* <Investment
            name="KILT"
            des="A blockchain identity protocol for issuing
              self-sovereign, anonymous, verifiable credentials and decentralized identifiers."
            logo="kilt.png"
            round="Public"
          /> */}
          <Investment
            name="Ondo Finance"
            des="Next generation of financial infrastructure to improve market efficiency, transparency, and accessibility."
            logo="ondo.png"
            round="Public"
          />
        </div>
        <div className="gap-6" />
      </div>
    </main>
  );
}
