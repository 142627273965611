/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Typed from "react-typed";

export default function Home() {
  return (
    <main className="main">
      <div className="container  app-width">
        <div className="gap-3" />
        <div className="row">
          <div className="col-12">
            <h1>DRI Venture Capital</h1>
            <p className="text-secondary fw-bold">
              <span className="terminal">We are</span>{" "}
              <Typed
                strings={[
                  "nurturing talents.",
                  "incubating early-state Web3 startups.",
                  "also BUILING with you...",
                ]}
                typeSpeed={60}
                backSpeed={50}
                loop
              />
            </p>

            <p>
              Our vision is to leverage the power of on-chain data to make
              informed investment decisions. We believe that data is the key to
              unlocking the full potential of the crypto market. By utilizing
              data-driven insights, we aim to identify and support promising
              projects in the space that have the potential to shape the future
              of decentralized finance.
            </p>

            <p>
              Our ultimate goal is to be 100% focused on supporting projects
              that are shaping the future of finance. To achieve this, we
              incubate, fund, connect and build with startups.
            </p>

            <p className="text-secondary fw-bold terminal">
              Ready to take the opportunity together? Email us:{" "}
              <a href="mailto:hi@dri.vc">hi@dri.vc</a>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
