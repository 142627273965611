export default function LargeIconLayout(props) {
  return (
    <main className="main">
      <div className="container app-width">
        <div className="row mt-3 justify-content-center">
          <div className="col-lg-6 my-5 text-center">
            <p>
              <img src={props.icon} className="game-icon" alt="" />
            </p>
            <h1>{props.title}</h1>
            <p className="lead">{props.des}</p>
          </div>
        </div>
        <div className="gap-3"></div>
        <div className="row justify-content-center">
          <div className={props.contentClassName || "col-lg-8"}>
            {props.children}
          </div>
        </div>

        <div className="gap-3"></div>
      </div>
      <div className="gap-6"></div>
    </main>
  );
}
