import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from "./utils/ScrollToTop";

import Home from "./Home";
import Portfolio from "./Portfolio";
import Research from "./Research";

import NotFound from "./NotFound";

import Header from "./Header";
import Footer from "./Footer";

export default function Container() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <ScrollToTop>
            <Home />
          </ScrollToTop>
        </Route>

        <Route exact path="/portfolio">
          <ScrollToTop>
            <Portfolio />
          </ScrollToTop>
        </Route>

        <Route exact path="/research">
          <ScrollToTop>
            <Research />
          </ScrollToTop>
        </Route>

        <Route exact path="">
          <ScrollToTop>
            <NotFound />
          </ScrollToTop>
        </Route>
      </Switch>

      <Footer />
    </Router>
  );
}
