import { Link } from "react-router-dom";

// import notfound from "../assets/images/notfound.svg";

import LargeIconLayout from "./layouts/LargeIconLayout";

export default function NotFound() {
  return (
    <LargeIconLayout
      // icon={notfound}
      title="404"
      des="Look like you're lost!
    the page you are looking for not available!"
    >
      <div className="row justify-content-center">
        <p className="text-center">
          <Link to="/" className="btn btn-cta btn-link">
            Go to homepage!
          </Link>
        </p>
      </div>
    </LargeIconLayout>
  );
}
