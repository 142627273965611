import { Link } from "react-router-dom";
import { SOCIAL_LINK } from "./constants";
import {
  FaMediumM,
  FaTwitter,
  FaTelegramPlane,
  FaGithub,
  FaDiscord,
  FaBook,
} from "react-icons/fa";

// import { BsBoxArrowUpRight } from "react-icons/bs";
// import w3f from "../assets/images/w3fg.svg";

export default function Footer() {
  return (
    <footer className="footer mt-auto ">
      <div className="text-secondary">
        <div className="container app-width">
          <hr />
          {/* <div className="gap-1"></div> */}

          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col text-lg-start">
              <span className="terminal">DRI Venture Capital</span>
            </div>
            <div className="col text-lg-end">
              <a
                target="_blank"
                rel="noreferrer"
                className=""
                href={`emailto:${SOCIAL_LINK.email}`}
              >
                {SOCIAL_LINK.email}
              </a>
            </div>
          </div>
          <div className="gap-3"></div>
        </div>
      </div>
    </footer>
  );
}
